<template>
  <PostProductionComponent></PostProductionComponent>
</template>

<script>
  import PostProductionComponent from "../../components/services/PostProductionComponent";
    export default {
      name: "PostProduction",
      components: {
        PostProductionComponent
      }
    }
</script>

<style scoped>

</style>
