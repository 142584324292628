<template>
  <SupportComponent></SupportComponent>
</template>

<script>
  import SupportComponent from "../../components/services/SupportComponent";
    export default {
      name: "Support",
      components: {
        SupportComponent
      }
    }
</script>

<style scoped>

</style>
