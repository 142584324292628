<template>
  <ServicesComponent></ServicesComponent>
</template>

<script>
  import ServicesComponent from "../../components/services/ServicesComponent";
    export default {
      name: "Services",
      components: {
        ServicesComponent
      }
    }
</script>

<style scoped>

</style>
