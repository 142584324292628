<template>
  <MixdownComponent></MixdownComponent>
</template>

<script>
  import MixdownComponent from "../../components/services/MixdownComponent";
    export default {
      name: "Mixdown",
      components: {
        MixdownComponent
      }
    }
</script>

<style scoped>

</style>
