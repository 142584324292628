<template>
  <ComposingComponent></ComposingComponent>
</template>

<script>
  import ComposingComponent from "../../components/services/ComposingComponent";
    export default {
      name: "Composing",
      components: {
        ComposingComponent
      }
    }
</script>

<style scoped>

</style>
