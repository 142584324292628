<template>
  <ReferencesComponent></ReferencesComponent>
</template>

<script>
  import ReferencesComponent from "../components/references/ReferencesComponent";
    export default {
      name: "References",
      components: {
        ReferencesComponent
      }
    }
</script>

<style scoped>

</style>
