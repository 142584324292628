<template>
    <v-container>
        <v-row>
            <v-col>
                <h1 class="font-weight-light primary--text">Post Production</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" xs="12" sm="4">
                <img :src="postProduction.source" width="100%" alt="">
            </v-col>
            <v-col xs="12" sm="8">
                <h2 class="font-weight-light">{{ $t('postProduction.main') }}</h2>
                <br>
                <h2 class="font-weight-light">{{ $t('interested') }}</h2>
                <v-col sm="1" offset-md="4">
                <v-btn to="/contact">
                    <v-icon
                    color="primary"
                    >
                    mdi-form-select
                    </v-icon>
                    {{ $t('contactForm') }}
                </v-btn>
                </v-col>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "PostProduction",
                data: () => ({ 
            postProduction: {
                source: require('@/assets/graphics/services/postProduction.jpg'),
                alt: 'Post Production'
            }
        })
    }
</script>

<style scoped>

</style>
