export default {
    noDataText: 'No data available',
    navigation: {
        home: 'Home',
        service: 'Angebot',
        about: 'Über mich',
        references: 'Referenzen',
        contact: 'Kontakt'
    },
    home: {
        title: 'Home',
        welcome: 'Dein individueller, moderner, intuitiver Dienstleister und Ansprechpartner für die Audiowelt.'
    },
    contact: {
        title: 'Kontakt',
        address: 'Anschrift',
        contactForm: {
            title: 'Kontaktformular',
            name: 'Name',
            email: 'E-Mail',
            message: 'Nachricht',
            submit: 'Absenden',
            reset: 'Zurücksetzen',
            rules: {
                name: 'Bitte geben Sie einen Namen ein.',
                message: 'Bitte geben Sie eine Nachricht ein',
            }
        }
    },
    contactForm: 'Kontaktformular',
    about: {
        title: 'Über mich',
        mainOne: 'Seit ich jung bin begleitet und fasziniert mich Musik und Audio sehr stark. Nach 14 Jahren als Musiker und 7 Jahren als DJ und Produzent entschied ich mich, mein Wissen und Können zu professionalisieren. Ich habe mein Studium in Audio Engineering mit Vertiefung Music Production am SAE Institut Zürich erfolgreich abgeschlossen.',
        mainTwo: 'Neben der Arbeit im Studio, an Filmsets und der Bühne arbeite ich im Club Elysia Basel als Tontechniker sowie als eigenständiger Techniker in vielen Projekten. Durch langjährige Erfahrung als Künstler, Tontechniker und Klangfanatiker habe ich ein grosses Know-how in allen Bereichen von Audio und kann jedem Wunsch nachgehen und ihn verwirklichen.'
    },
    interested: 'Wenn Du weitere Informationen über diesen Service erhalten möchtest, dann fülle bitte das Kontaktformular aus.',
    service: 'Angebot',
    mixdown: {
        main: 'Sobald das Sound Design und das Arrangement vollendet sind, kommt der Mixdown zum Zug. Overdrive Audio bietet Dir einen professionellen Mixdown Service an, der Deinen Tracks eine schöne Balance, Transparenz und Durchsetzungsvermögen verleiht. Mit chirurgischen Werkzeugen sowie klangverbessernder Technik und Know-how, erfüllt Overdrive Audio Deine Wünsche zu Deinem Song.'
    },
    composing: {
        main: 'Overdrive Audio lässt Bild mit der passenden Musik zusammenschmelzen. Egal ob Spielfilme, Dokumentationen, Werbung oder Games, Overdrive Audio lässt keine Wünsche und Vorstellungen übrig.'
    },
    feedback: {
        main: 'Hast Du Tracks produziert und kommst nicht mehr weiter oder möchtest eine professionelle Meinung? Overdrive Audio bietet Dir einen Feedback Service an, der Dich und Deine Tracks voran bringen wird.'
    },
    mastering: {
        main: 'Möchtest Du Deinen Track kraftvoll und konsumerfreundlich gestalten? Overdrive Audio lässt keine Wünsche offen für Deine Tracks, Alben und DJ-Mixes. Mit digitaler wie auch analoger Technik und Wissen vergoldet Overdrive Audio Dein Material nach Deinen Vorstellungen.',
        trackAlbumMastering: 'Deine Tracks sind fertig abgemischt und brauchen noch die letzte Politur? Overdrive Audio bietet Dir einen professionellen Mastering Service an und lässt damit Deine Songs und Alben glänzen.',
        djSetMastering: 'Du hast einen DJ Mix aufgenommen und möchtest ihn professionell klingen lassen? Overdrive Audio gibt Deinem DJ Mix den letzten Schliff und lässt ihn mächtig und druckvoll klingen.'
    },
    postProduction: {
        main: 'Overdrive Audio bietet Dir einen professionellen Post Production Service an. Egal ob für Podcasts, Werbungen, Filme jeglicher Art oder sonstiges Audiomaterial, Overdrive Audio garantiert, Dein Material konsumfähiger zu machen und glänzen zu lassen.'
    },
    producing: {
        main: 'Du hast eine Idee und weisst nicht, wie Du sie verwirklichen kannst? Overdrive Audio verwandelt Deine Ideen mit langjähriger Erfahrung. Egal ob elektronische Musik, Hiphop oder Metal, der Producing Service ist nicht genregebunden und lässt Deinen Fantasien freien Lauf.'
    },
    producingCourses: {
        main: 'Overdrive Audio bietet Dir einen Producing Kurs an, der nicht genreabhängig ist. Egal ob Anfänger/innen oder fortgeschritten, Overdrive Audio passt den Kurs individuell an Deine Wünsche und Vorstellungen an. Der Producing Kurs ist nicht DAW abhängig.'
    },
    soundDesign: {
        main: 'Möchtest Du einen individuellen Jingle für Deinen Podcast? Bist Du auf der Suche nach den passenden Klängen für Games oder Filme? Overdrive Audio bietet einen massgeschneiderten und innovativen Sound Design Service an.'
    },
    support: {
        main: 'Hast Du Schwierigkeiten, Dich für ein Gerät zu entscheiden? Möchtest Du ein Studio auf die Beine stellen oder Deinen Raum optimieren? Overdrive Audio hilft Dir in technischen Fragen und Installationen mit dem Technical / Set Up Support.'
    }
}
  