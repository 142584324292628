export default {
    noDataText: 'No data available',
    navigation: {
        home: 'Home',
        service: 'Service',
        about: 'About',
        references: 'References',
        contact: 'Contact'
    },
    home: {
        title: 'Home',
        welcome: 'Your individual, modern, intuitive service provider and contact for the world of audio.'
    },
    contact: {
        title: 'Contact',
        address: 'Address',
        contactForm: {
            title: 'Contact Form',
            name: 'Name',
            email: 'E-Mail',
            message: 'Message',
            submit: 'Submit',
            reset: 'Clear',
            rules: {
                name: 'Please enter a name.',
                message: 'Please enter a message',
            },
        }
    },
    contactForm: 'Contact Form',
    about: {
        title: 'About',
        mainOne: 'Since I was young, music and audio has accompanied and fascinated me greatly. After 14 years as a musician and 7 years as a DJ and producer, I decided to professionalize my knowledge and skills. I successfully completed my studies in audio engineering with specialization in music production at the SAE Institute Zurich.',
        mainTwo: 'Besides working in studios, on film sets and the stage, I work at Club Elysia Basel as a sound engineer as well as an independent technician in many other projects. Through many years of experience as an artist, audio engineer and sound fanatic, I have a huge know-how in all areas of audio and can pursue and realize every wish.'
    },
    interested: 'If you would like to receive more information about this service, please fill out the contact form below.',
    service: 'Service',
    mixdown: {
        main: 'Once the sound design and arrangement are complete, the mixdown comes into play. Overdrive Audio offers you a professional mixdown service that gives your tracks a nice balance, transparency and assertiveness. With surgical tools as well as sound enhancing technology and know-how, Overdrive Audio will fulfil your wishes for your song.'
    },
    composing: {
        main: 'Overdrive Audio lets pictures melt together with the suitable music. Whether feature films, documentaries, commercials or games, Overdrive Audio leaves nothing to be desired..'
    },
    feedback: {
        main: 'Have you produced tracks and are stuck or need professional assistance? Overdrive Audio offers you a feedback service that will bring you and your tracks forward.'
    },
    mastering: {
        main: 'Want to make your track powerful and consumer friendly? Overdrive Audio leaves nothing to be desired for your tracks, albums and DJ mixes. With digital as well as analog technology and knowledge Overdrive Audio gilds your material according to your ideas..',
        trackAlbumMastering: 'Your tracks are mixed but need polishing up? Overdrive Audio offers you a professional mastering service and lets your songs and albums shine.',
        djSetMastering: 'You have recorded a DJ mix and want to make it sound professional? Overdrive Audio gives your DJ mix the final touch and makes it sound powerful and punchy.'
    },
    postProduction: {
        main: 'Overdrive Audio offers you a professional post production service. Whether for podcasts, commercials, films of any kind or any other audio material, Overdrive Audio guarantees to make your material more consumable and to let it shine.'
    },
    producing: {
        main: 'You have an idea and don\'t know how to realize it? Let Overdrive Audio\'s years of experience turn your ideas into reality. Whether electronic music, hiphop or metal, the producing service is not genre-bound and gives free rein to your fantasies.'
    },
    producingCourses: {
        main: 'Overdrive Audio offers you a producing course that is not genre dependent. No matter if you are a beginner or advanced, Overdrive Audio tailors the course to meet your individual wishes and ideas. The producing course is not DAW dependent.'
    },
    soundDesign: {
        main: 'Do you need an personalised jingle for your podcast? Are you looking for the right sounds for games or movies? Overdrive Audio offers an individual and innovative sound design service.'
    },
    support: {
        main: 'Are you having trouble deciding on a piece of equipment? Would you like to set up a studio or optimize use of the available space? Overdrive Audio helps you with technical questions and installations with the technical / set up Support.'
    }
}
  