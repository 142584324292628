<template>
  <ProducingCoursesComponent></ProducingCoursesComponent>
</template>

<script>
  import ProducingCoursesComponent from "../../components/services/ProducingCoursesComponent";
    export default {
      name: "ProducingCourses",
      components: {
        ProducingCoursesComponent
      }
    }
</script>

<style scoped>

</style>
