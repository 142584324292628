<template>
  <ProducingComponent></ProducingComponent>
</template>

<script>
  import ProducingComponent from "../../components/services/ProducingComponent";
    export default {
      name: "Producing",
      components: {
        ProducingComponent
      }
    }
</script>

<style scoped>

</style>
