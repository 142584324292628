<template>
  <v-card overflow hidden>
    <v-app-bar app fixed flat dark color="anthrazit" class="hidden-xs-and-down">
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-xs-only">
        <template v-for="item in items">
          <v-btn text v-if="typeof item.link == 'string'" :key="item.title" :to="item.link"> 
            {{ item.title }}
          </v-btn>
          <v-menu v-else :prepend-icon="item.icon" :key="item.title" offset-y>
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">
                {{ item.title }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="item in item.link" :key="item.title" :to="item.link">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-btn @click="switchLanguage()"><b>DE / EN</b></v-btn>
    </v-app-bar>

    <v-app-bar fixed flat dark color="anthrazit" class="hidden-sm-and-up">
        <a href="/"><v-img :src="logo.source" max-width="10em"></v-img></a>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="dialog = true"></v-app-bar-nav-icon>
        <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card>
            <v-toolbar flat>
              <v-toolbar-title>Overdrive Audio</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-app-bar-nav-icon @click="dialog = false"></v-app-bar-nav-icon>
            </v-toolbar>
            <template v-for="item in items"> 
              <v-list-item v-if="typeof item.link == 'string'" :key="item.title" :to="item.link" @click="dialog = false">
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-group v-else :key="item.title" no-action v-model="item.expand">
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item v-for="item in item.link" :key="item.title" :to="item.link" @click="dialog = false">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list-group>
            </template>
          </v-card>
        </v-dialog>
      </v-app-bar>
  </v-card>
</template>

<script>
  export default {
    name: "NavigationBar",
    data() {
      return {
        dialog: false,
        items: [
          {title: 'Home', link: '/', active: false, auth: false},
          {title: 'Services', expand: false, link: [
              {title: 'All Services', link: '/services', active: false, auth: false},
              {title: 'Mixdown', link: '/services/mixdown', active: false, auth: false},
              {title: 'Mastering', link: '/services/mastering', active: false, auth: false},
              {title: 'Post Production', link: '/services/post-production', active: false, auth: false},
              {title: 'Composing', link: '/services/composing', active: false, auth: false},
              {title: 'Producing', link: '/services/producing', active: false, auth: false},
              {title: 'Producing Course', link: '/services/producing-courses', active: false, auth: false},
              {title: 'Sound Design', link: '/services/sound-design', active: false, auth: false},
              {title: 'Technical / Set Up Support', link: '/services/support', active: false, auth: false},
              {title: 'Feedback', link: '/services/feedback', active: false, auth: false},
            ]
          },
          {title: 'About', link: '/about', active: false, auth: false},
          //{title: 'References', link: '/references', active: false, auth: false},
          {title: 'Contact', link: '/contact', active: false, auth: false},
          /*{title: 'Immobilien', expand: false, link: [
              {title: 'Aktuelle Immobilien', link: '/immobilien/aktuelle-immobilien', active: false, auth: false},
              {title: 'Abgeschlossene Immobilien', link: '/immobilien/abgeschlossene-immobilien', active: false, auth: false},
              {title: 'Personalisierter Suchauftrag', link: '/immobilien/personalisierter-suchauftrag', active: false, auth: false},
            ]
          },*/
        ],
        logo: {
          source: require('@/assets/logo-white-background.png'),
          alt: 'Overdrive Audio Logo'
        },
      }
    },
    methods: {
      switchLanguage() {
        if (this.$i18n.locale == 'de') {
          this.$i18n.locale = "en";
          this.$store.dispatch('language/setLanguage', "en");
        } else {
          this.$i18n.locale = "de";
          this.$store.dispatch('language/setLanguage', "de");
        }
      },
    }
  }
</script>

<style scoped>

</style>
