<template>
    <v-container>
        <v-row>
            <v-col>
                <h1 class="font-weight-light primary--text">{{ $t('contact.title') }}</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="6" md="4" offset-md="1">
                <v-container>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('contact.address') }}</v-list-item-title>
                            <v-list-item-subtitle>Overdrive Audio</v-list-item-subtitle>
                            <v-list-item-subtitle>Gregory Bloch</v-list-item-subtitle>
                            <v-list-item-subtitle>Feldbergstrasse 99</v-list-item-subtitle>
                            <v-list-item-subtitle>4057 Basel</v-list-item-subtitle>
                            <v-list-item-subtitle>Schweiz</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-container>
            </v-col>
            <v-col xs="6" md="4">
                <v-container>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Email</v-list-item-title>
                            <v-list-item-subtitle><a href="mailto:info@overdrive-audio.com">info@overdrive-audio.com</a></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Mobile</v-list-item-title>
                            <v-list-item-subtitle><a href="tel:+41765006430">+41 76 500 64 30</a></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-container>
            </v-col>
            <v-col xs="6" md="3">
                <v-container>
                    <a target="_blank" style="text-decoration: none" href="https://instagram.com/overdrive__audio">
                        <v-icon
                        large
                        color="primary"
                        >
                        mdi-instagram
                        </v-icon>
                    </a>
                    <a target="_blank" style="text-decoration: none" href="https://www.facebook.com/Overdrive-Audio-106640654853299">
                        <v-icon
                        large
                        color="primary"
                        >
                        mdi-facebook
                        </v-icon>
                    </a>
                </v-container>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" md="8" offset-md="2">
                <v-container>
                    <h2 class="font-weight-light primary--text">{{ $t('contact.contactForm.title') }}</h2><br>
                    <v-form
                        ref="form"
                        v-model="valid"
                        id="mailForm"
                        @submit.prevent="submit"
                    >
                        <v-text-field
                        v-model="from_name"
                        name="from_name"
                        :rules="[v => !!v || $t('contact.contactForm.rules.name')]"
                        :label="$t('contact.contactForm.name')"
                        required
                        ></v-text-field>

                        <v-select
                        :items="services"
                        v-model="service"
                        name="service"
                        label="Service"
                        required
                        ></v-select>

                        <v-text-field
                        v-model="email"
                        name="email"
                        :rules="emailRules"
                        label="E-mail"
                        required
                        ></v-text-field>

                        <v-text-field
                        v-model="message"
                        name="message"
                        :counter="500"
                        :rules="[v => !!v || $t('contact.contactForm.rules.message')]"
                        :label="$t('contact.contactForm.message')"
                        required
                        ></v-text-field>

                        <v-btn
                            class="mr-4"
                            :disabled="!valid"
                            @click="submit"
                        >
                            {{ $t('contact.contactForm.submit') }}
                        </v-btn>
                        <v-btn @click="clear">
                            {{ $t('contact.contactForm.reset') }}
                        </v-btn>
                    </v-form>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import emailjs from 'emailjs-com';
    import configuration from "../../plugins/configuration";
    const serviceId = `${configuration.value('emailServiceId')}`
    const templateId = `${configuration.value('emailTemplateId')}`
    const userId = `${configuration.value('emailUserId')}`

    export default {
        name: 'Contact',
        data() {
            return {
                services: ['Mixdown', 'Mastering', 'Post Production', 'Composing' , 'Producing', 'Producing Course', 'Sound Design', 'Technical / Set Up Support', 'Feedback'],
                valid: false,
                from_name: '',
                email: '',
                service: '',
                emailRules: [
                    v => !!v || 'Please enter an email address',
                    v => /.+@.+\..+/.test(v) || 'Email address is invalid',
                ],
                message: '',
            }
        },
        methods: {
            clear () {
                this.name = ''
                this.service = ''
                this.email = ''
                this.message = ''
                this.$refs.observer.reset()
            },
            submit(e) {
                try {
                    emailjs.sendForm(serviceId, templateId, mailForm,
                    userId, {
                    name: this.name,
                    service: this.service,
                    email: this.email,
                    message: this.message
                    })
                    this.successfulSnack('Mail sent successfully')
                    this.$router.push('/');
                } catch(error) {
                    console.log(error)
                    // fail snack
                    this.failedSnack('Mail could not be delivered, please contact info@overdrive-audio.com')
                }
            },
            // snacks
            successfulSnack (msg) {
                this.$store.commit("snackColor", "success")
                this.$store.commit("snack", `${msg}`)
            },
            failedSnack (msg) {
                this.$store.commit("snackColor", "error")
                this.$store.commit("snack", `${msg}`)
            },
        }
    }
</script>


<style scoped>

</style>
