<template>
  <HomeComponent></HomeComponent>
</template>

<script>
  import HomeComponent from "../components/home/HomeComponent";
    export default {
      name: "Home",
      components: {
        HomeComponent
      }
    }
</script>

<style scoped>

</style>
