<template>
  <AboutComponent></AboutComponent>
</template>

<script>
  import AboutComponent from "../components/about/AboutComponent";
    export default {
      name: "About",
      components: {
        AboutComponent
      }
    }
</script>

<style scoped>

</style>
