<template>
    <v-container fill-height fluid style="background: no-repeat center center fixed; background-size: cover;" v-bind:style="{ backgroundImage: 'url(' + cover.source + ')' }">
        <v-row
        align="center"
        justify="center"
        >
            <v-col
            align="center"
            cols="12"
            >
                <v-img v-if="!$isMobile()" :src="logo.source" max-width="40%">
                </v-img>
                <v-img v-else :src="logo.source" max-width="100%">
                </v-img>
                <br>
                <h1 class="font-weight-bold primary--text">{{ $t('home.welcome') }}</h1>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "Home",
        data: () => ({ 
            logo: {
                source: require('@/assets/logo-amber.png'),
                alt: 'Overdrive Audio Logo'
            },
            cover: {
                source: require('@/assets/graphics/home/cover.jpg'),
                alt: 'Overdrive Audio Cover'
            }
        })
    }
</script>

<style scoped>

</style>
