<template>
  <v-app id="inspire">
    <!--<v-snackbar
      v-model="snackbar"
      :color="color"
      :top="true"
    >
      {{ message}}

      <v-btn
        dark
        text
        @click="removeSnack()"
      >
        Close
      </v-btn>
    </v-snackbar> -->


    <!-- navbar -->
    
    <template v-if="$isMobile()">
      <v-container>
        <NavigationBar></NavigationBar>
      </v-container>
    </template>
    <template v-else>
      <NavigationBar></NavigationBar>
    </template>
    

    <!-- main content -->
    <v-main>
      <router-view></router-view>
    </v-main>

    <!-- app footer  -->
    <v-footer dark color="anthrazit" app>
      <span>&copy; Overdrive Audio {{ year }} | webdesign by Noah Denger</span>
    </v-footer>
  </v-app>
</template>

<script>
  import Repository from "@/repository/RepositoryFactory";
  const auth = Repository.get('auth')
  import NavigationBar from './components/navigation/NavigationBar'

  export default {
    props: {
      source: String,
    },
    components: {
      NavigationBar,
    },
    template: {},

    data: () => ({
      dialog: false,
      title: process.env.TITLE || 'Overdrive Audio',
      year: new Date().getFullYear(),
      logo: {
        source: require('@/assets/logo.png'),
        alt: 'Overdrive Audio'
      },
      color: '',
      snackbar: false,
      timeout: 6000,
      message: '',
      timoutId: null,
      menu: false,
    }),

    computed: {
      user(state) {
        try {
          return this.$store.state.authenticated
        }catch (e) {
          return false
        }
      }
    },

    created () {
      this.$store.watch(state => state.snack, () => {
        if (this.$store.getters.snack.length > 0 && !this.snackbar){
          this.nextSnack()
        }
      })
    },
    methods: {
      nextSnack() {
        this.snackbar = true
        this.message = this.$store.getters.snack[0]
        this.color = this.$store.getters.snackColor[0]
        this.timeoutId = setTimeout(() => {this.removeSnack()}, this.timeout)
      },
      removeSnack() {
        clearTimeout(this.timeoutId)
        this.timeoutId = null
        this.$store.commit("removeSnack")
        this.$store.commit("removeSnackColor")
        if (this.$store.getters.snack.length > 0){
          this.nextSnack()
        } else {
           this.snackbar = false
        }

      },
      async logout() {
        try {
          const response = await auth.logout()
          this.$store.commit("authenticated", false)
          this.$store.commit("snackColor", "success")
          this.$store.commit("snack", `Logout was succesfull`)
          this.$router.push({name: "Login"})
        } catch (e){
          this.$store.commit("snackColor", "error")
          this.$store.commit("snack", `Logout failed`)
        }
      }
    },
    destroyed() {
      if (this.timeoutId !== null){
        this.removeSnack()
      }
    }
  }
</script>

<style scoped>
  html, body {
    overflow-x: hidden;
  }
  body {
    position: relative
  }
</style>
