<template>
    <v-container>
        <v-row>
            <v-col>
                <h1 class="font-weight-light primary--text">{{ $t('about.title') }}</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" xs="12" sm="4">
                <img :src="gregoire.source" width="100%" alt="">
                <br>
                <h3>Gregory Bloch</h3>
            </v-col>
            <v-col xs="12" sm="8">
                <h2 class="font-weight-light">{{ $t('about.mainOne') }}</h2>
                <br>
                <h2 class="font-weight-light">{{ $t('about.mainTwo') }}</h2>
                <v-col sm="1" offset-md="4">
                </v-col>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "AboutComponent",
            data: () => ({ 
            gregoire: {
                source: require('@/assets/graphics/about/gregoire.jpg'),
                alt: 'Overdrive Audio Logo'
            }
        })
    }
</script>

<style scoped>

</style>
