<template>
    <v-container>
        <v-row>
            <v-col>
                <h1 class="font-weight-light primary--text">{{ $t('service') }}</h1>
            </v-col>
        </v-row>

        <v-row>
            <v-col
            class="d-flex child-flex"
            cols="12"
            xs="12"
            sm="6"
            md="4"
            >
                <router-link to="/services/mixdown">
                    <v-img
                        :src="mixdown.source"
                        aspect-ratio="1"
                        class="grey lighten-2"
                    >
                        <v-container fill-height fluid>
                            <v-row
                            align="center"
                            justify="center"
                            >
                                <h1 class="font-weight-bold primary--text">Mixdown</h1>
                            </v-row>
                        </v-container>
                    </v-img>
                </router-link>
            </v-col>
            <v-col
            class="d-flex child-flex"
            cols="12"
            xs="12"
            sm="6"
            md="4"
            >
                <router-link to="/services/mastering">
                    <v-img
                        :src="mastering.source"
                        aspect-ratio="1"
                        class="grey lighten-2"
                    >
                        <v-container fill-height fluid>
                            <v-row
                            align="center"
                            justify="center"
                            >
                                <h1 class="font-weight-bold primary--text">Mastering</h1>
                            </v-row>
                        </v-container>
                    </v-img>
                </router-link>
            </v-col>
            <v-col
            class="d-flex child-flex"
            cols="12"
            xs="12"
            sm="6"
            md="4"
            >
                <router-link to="/services/post-production">
                    <v-img
                        :src="postProduction.source"
                        aspect-ratio="1"
                        class="grey lighten-2"
                    >
                        <v-container fill-height fluid>
                            <v-row
                            align="center"
                            justify="center"
                            >
                                <h1 class="font-weight-bold primary--text">Post Production</h1>
                            </v-row>
                        </v-container>
                    </v-img>
                </router-link>
            </v-col>
            <v-col
            class="d-flex child-flex"
            cols="12"
            xs="12"
            sm="6"
            md="4"
            >
                <router-link to="/services/composing">
                    <v-img
                        :src="composing.source"
                        aspect-ratio="1"
                        class="grey lighten-2"
                    >
                        <v-container fill-height fluid>
                            <v-row
                            align="center"
                            justify="center"
                            >
                                <h1 class="font-weight-bold primary--text">Composing</h1>
                            </v-row>
                        </v-container>
                    </v-img>
                </router-link>
            </v-col>
            <v-col
            class="d-flex child-flex"
            cols="12"
            xs="12"
            sm="6"
            md="4"
            >
                <router-link to="/services/producing">
                    <v-img
                        :src="producing.source"
                        aspect-ratio="1"
                        class="grey lighten-2"
                    >
                        <v-container fill-height fluid>
                            <v-row
                            align="center"
                            justify="center"
                            >
                                <h1 class="font-weight-bold primary--text">Producing</h1>
                            </v-row>
                        </v-container>
                    </v-img>
                </router-link>
            </v-col>
            <v-col
            class="d-flex child-flex"
            cols="12"
            xs="12"
            sm="6"
            md="4"
            >
                <router-link to="/services/producing-courses">
                    <v-img
                        :src="producingCourse.source"
                        aspect-ratio="1"
                        class="grey lighten-2"
                    >
                        <v-container fill-height fluid>
                            <v-row
                            align="center"
                            justify="center"
                            >
                                <h1 class="font-weight-bold primary--text">Producing Course</h1>
                            </v-row>
                        </v-container>
                    </v-img>
                </router-link>
            </v-col>
            <v-col
            class="d-flex child-flex"
            cols="12"
            xs="12"
            sm="6"
            md="4"
            >
                <router-link to="/services/sound-design">
                    <v-img
                        :src="soundDesign.source"
                        aspect-ratio="1"
                        class="grey lighten-2"
                    >
                        <v-container fill-height fluid>
                            <v-row
                            align="center"
                            justify="center"
                            >
                                <h1 class="font-weight-bold primary--text">Sound Design</h1>
                            </v-row>
                        </v-container>
                    </v-img>
                </router-link>
            </v-col>
            <v-col
            class="d-flex child-flex"
            cols="12"
            xs="12"
            sm="6"
            md="4"
            >
                <router-link to="/services/support">
                    <v-img
                        :src="support.source"
                        aspect-ratio="1"
                        class="grey lighten-2"
                    >
                        <v-container fill-height fluid>
                            <v-row
                            align="center"
                            justify="center"
                            >
                                <h1 class="font-weight-bold primary--text" style="text-align:center;">Technical / Set Up <br>Support</h1>
                            </v-row>
                        </v-container>
                    </v-img>
                </router-link>
            </v-col>
            <v-col
            class="d-flex child-flex"
            cols="12"
            xs="12"
            sm="6"
            md="4"
            >
                <router-link to="/services/feedback">
                    <v-img
                        :src="feedback.source"
                        aspect-ratio="1"
                        class="grey lighten-2"
                    >
                        <v-container fill-height fluid>
                            <v-row
                            align="center"
                            justify="center"
                            >
                                <h1 class="font-weight-bold primary--text">Feedback</h1>
                            </v-row>
                        </v-container>
                    </v-img>
                </router-link>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "Services",
        data: () => ({ 
            logo: {
                source: require('@/assets/logo-amber.png'),
                alt: 'Overdrive Audio Logo'
            },
            composing: {
                source: require('@/assets/graphics/services/composing.jpg'),
                alt: 'Overdrive Audio Cover'
            },
            feedback: {
                source: require('@/assets/graphics/services/feedback.jpg'),
                alt: 'Overdrive Audio Cover'
            },
            mastering: {
                source: require('@/assets/graphics/services/mastering.jpeg'),
                alt: 'Overdrive Audio Cover'
            },
            producingCourse: {
                source: require('@/assets/graphics/services/producingCourse.jpg'),
                alt: 'Overdrive Audio Cover'
            },
            mixdown: {
                source: require('@/assets/graphics/services/mixdown.jpg'),
                alt: 'Overdrive Audio Cover'
            },
            postProduction: {
                source: require('@/assets/graphics/services/postProduction.jpg'),
                alt: 'Overdrive Audio Cover'
            },
            producing: {
                source: require('@/assets/graphics/services/producing.jpg'),
                alt: 'Overdrive Audio Cover'
            },
            soundDesign: {
                source: require('@/assets/graphics/services/soundDesign.jpg'),
                alt: 'Overdrive Audio Cover'
            },
            support: {
                source: require('@/assets/graphics/services/support.jpg'),
                alt: 'Overdrive Audio Cover'
            }
        })
    }
</script>

<style scoped>
a {
    text-decoration: none;
}
</style>
