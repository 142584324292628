// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import vuetify from '@/plugins/vuetify'
import store from '@/plugins/vuex'
import VueCookies from 'vue-cookies'
import VueMobileDetection from 'vue-mobile-detection'
import { i18n } from './plugins/i18n.js'


Vue.config.productionTip = false

Vue.use(VueMobileDetection)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  vuetify,
  store,
  i18n,
  render: function (h) { return h(App) }
}).$mount('#inspire')

Vue.use(VueCookies)

Vue.$cookies.config('Infinity')
