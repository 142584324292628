import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import de from '../translations/locales/de';
import en from '../translations/locales/en';

Vue.use(Vuetify)


const opts = {
  lang: {
    locales: { de, en },
    current: 'de'
  },
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#FFBF00',
        amber: '#532D84',
        anthrazit: '#293133'
        // text: colors.grey.darken3,
        // secondary: '#424242',
        // accent: '#82B1FF',
        // error: '#FF5252',
        // info: '#2196F3',
        // success: '#4CAF50',
        // warning: '#FFC107',
      }
    },
  },
}

export default new Vuetify(opts)
