<template>
  <ContactComponent></ContactComponent>
</template>

<script>
  import ContactComponent from "../components/contact/ContactComponent";
    export default {
      name: "Contact",
      components: {
        ContactComponent
      }
    }
</script>

<style scoped>

</style>
