<template>
  <MasteringComponent></MasteringComponent>
</template>

<script>
  import MasteringComponent from "../../components/services/MasteringComponent";
    export default {
      name: "Mastering",
      components: {
        MasteringComponent
      }
    }
</script>

<style scoped>

</style>
