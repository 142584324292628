<template>
  <FeedbackComponent></FeedbackComponent>
</template>

<script>
  import FeedbackComponent from "../../components/services/FeedbackComponent";
    export default {
      name: "Feedback",
      components: {
        FeedbackComponent
      }
    }
</script>

<style scoped>

</style>
