import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import Services from '@/views/services/Services'
import ServicesMixdown from '@/views/services/Mixdown'
import ServicesMastering from '@/views/services/Mastering'
import ServicesProducing from '@/views/services/Producing'
import ServicesProducingCourses from '@/views/services/ProducingCourses'
import ServicesComposing from '@/views/services/Composing'
import ServicesPostProduction from '@/views/services/PostProduction'
import ServicesSoundDesign from '@/views/services/SoundDesign'
import ServicesSupport from '@/views/services/Support'
import ServicesFeedback from '@/views/services/Feedback'
import About from '@/views/About'
import References from '@/views/References'
import Contact from '@/views/Contact'
import store from '@/plugins/vuex';
import { i18n } from '../plugins/i18n.js';

// import Repository from '@/repository/RepositoryFactory'
// const auth = Repository.get('auth')


Vue.use(VueRouter)
  const routes= [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        allowAnonymous: true,
        requiresAuth: false
      }
    },
    {
      path: '/services',
      name: 'Services',
      component: Services,
      meta: {
        allowAnonymous: true,
        requiresAuth: false
      }
    },
    {
      path: '/services/mixdown',
      name: 'ServicesMixdown',
      component: ServicesMixdown,
      meta: {
        allowAnonymous: true,
        requiresAuth: false
      }
    },
    {
      path: '/services/mastering',
      name: 'ServicesMastering',
      component: ServicesMastering,
      meta: {
        allowAnonymous: true,
        requiresAuth: false
      }
    },
    {
      path: '/services/producing',
      name: 'ServicesProducing',
      component: ServicesProducing,
      meta: {
        allowAnonymous: true,
        requiresAuth: false
      }
    },
    {
      path: '/services/producing-courses',
      name: 'ServicesProducingCourses',
      component: ServicesProducingCourses,
      meta: {
        allowAnonymous: true,
        requiresAuth: false
      }
    },
    {
      path: '/services/composing',
      name: 'ServicesComposing',
      component: ServicesComposing,
      meta: {
        allowAnonymous: true,
        requiresAuth: false
      }
    },
    {
      path: '/services/post-production',
      name: 'ServicesPostProduction',
      component: ServicesPostProduction,
      meta: {
        allowAnonymous: true,
        requiresAuth: false
      }
    },
    {
      path: '/services/sound-design',
      name: 'ServicesSoundDesign',
      component: ServicesSoundDesign,
      meta: {
        allowAnonymous: true,
        requiresAuth: false
      }
    },
    {
      path: '/services/support',
      name: 'ServicesSupport',
      component: ServicesSupport,
      meta: {
        allowAnonymous: true,
        requiresAuth: false
      }
    },
    {
      path: '/services/feedback',
      name: 'ServicesFeedback',
      component: ServicesFeedback,
      meta: {
        allowAnonymous: true,
        requiresAuth: false
      }
    },
    {
      path: '/about',
      name: 'About',
      component: About,
      meta: {
        allowAnonymous: true,
        requiresAuth: false
      }
    },
    {
      path: '/references',
      name: 'References',
      component: References,
      meta: {
        allowAnonymous: true,
        requiresAuth: false
      }
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact,
      meta: {
        allowAnonymous: true,
        requiresAuth: false
      }
    }
  ]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  // detect language
  if (store.state.language.language && store.state.language.language !== i18n.locale) {
    i18n.locale = store.state.language.language;
    next()
  } else if (!store.state.language.language) {
    store.dispatch('language/setLanguage', navigator.languages)
      .then(() => {
        i18n.locale = store.state.language.language;
        next()
      });
  } else {
    next()
  }

  // if (to.meta.requiresAuth) {
  //   const response = await auth.isAuthenticated()
  //   if (response[0]) {
  //     store.commit("authenticated", true);
  //     store.commit("username", response[1].data.username)
  //     store.commit("firstName", response[1].data.first_name)
  //     store.commit("lastName", response[1].data.last_name)
  //     store.commit("emailAddress", response[1].data.email)
  //     next()
  //   } else {
  //     store.commit("authenticated", false);
  //     next({name: "Login"})
  //   }
  // } else{
  //   next()
  // }
  // if (to.name == null){
  //   next({name: "Home"})
  // }
})

export default router
