import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import language from './modules/language';


Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    language,
  },
  state: {
    authenticated: false,
    username:"",
    firstName: "",
    lastName: "",
    emailAddress: "",
    snack:[],
    snackColor:[],
  },
  mutations: {
    authenticated(state, value){
      state.authenticated = value
    },
    username(state, name){
      state.username = name
    },
    firstName(state, name){
      state.firstName = name
    },
    lastName(state, name){
      state.lastName = name
    },
    emailAddress(state, email){
      state.emailAddress = email
    },
    snack(state, text){
      state.snack.push(text)
    },
    snackColor(state, color){
      state.snackColor.push(color)
    },
    removeSnack(state){
      state.snack.shift()
    },
    removeSnackColor(state){
      state.snackColor.shift()
    }
  },
  getters: {
    authenticated: (state) => state.authenticated,
    username: (state) => state.username,
    firstName: (state) => state.firstName,
    lastName: (state) => state.lastName,
    emailAddress: (state) => state.emailAddress,
    snack: (state) => state.snack,
    snackColor: (state) => state.snackColor,
  }
})

export default store
