<template>
    <v-container>
        <v-row>
            <v-col>
                <h1 class="font-weight-light primary--text">Referenzen</h1>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "Home",
    }
</script>


<style scoped>

</style>
