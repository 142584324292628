<template>
  <SoundDesignComponent></SoundDesignComponent>
</template>

<script>
  import SoundDesignComponent from "../../components/services/SoundDesignComponent";
    export default {
      name: "SoundDesign",
      components: {
        SoundDesignComponent
      }
    }
</script>

<style scoped>

</style>
